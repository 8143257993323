const handleConsentResponse = (res) => {
	let responseURI;
	try {
		responseURI = new URL(res.uri);
	} catch (err) {
		throw new Error('Invalid redirect_uri', err);
	}
	for (const key in res.params) {
		responseURI.searchParams.append(key, res.params[key]);
	}
	if (!res.response_mode) {
		//we did not get a response_mode query param
		//set to response_type default response_modes
		if (res.response_type === 'code') {
			res.response_mode = 'query';
		} else if (res.response_type === 'id_token') {
			res.response_mode = 'fragment';
		}
	}

	sessionStorage.clear(); //clears authorize_query_params, app

	switch (res.response_mode) {
		case 'form_post': {
			const formRef = document.createElement('form');
			formRef.setAttribute('method', 'POST');
			formRef.setAttribute('action', res.uri);
			if (res.params) {
				for (const key in res.params) {
					const inputRef = document.createElement('input');
					inputRef.setAttribute('type', 'hidden');
					inputRef.setAttribute('name', key);
					inputRef.setAttribute('value', res.params[key]);
					formRef.appendChild(inputRef);
				}
				document.body.appendChild(formRef);
			}
			formRef.submit();
			break;
		}
		case 'query': {
			// Do nothing - already added as search params
			window.location.href = responseURI.href;
			break;
		}
		default: {
			// Fragment
			responseURI.href = responseURI.href.replace('?', '#');
			window.location.href = responseURI.href;
			break;
		}
	}
};

export { handleConsentResponse };
