import { get, del, post, put } from './fetch.js';
import { checkVersion, getDeviceTheme, getDisplay, addSourcePropToPictureObj } from './common.js';
import { handleConsentResponse } from './consent.js';
import { logPlausibleEvent } from './plausible.js';
import { readTranslatedKey } from './i18n.js';
import { clearLocalAndSessionStorage } from './storage.js';
import {
	data,
	notification,
	countdown,
	showSpinner,
	mergeAccounts,
	showUpgradePreferredModal,
	isRemoteAuthClient
} from '$src/stores.js';
import { locale } from 'svelte-i18n';
import { get as getStore } from 'svelte/store';
import parser from 'ua-parser-js';
import { IS_PROD } from '$src/constants.js';

export const getProfile = async (query, { showNotification = true } = {}) => {
	let url = '/profile';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	const paramsStr = params.toString();
	if (paramsStr) {
		url += `?${paramsStr}`;
	}
	const result = await get(url);
	if (result?.profile?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type for result?.profile?.language is string and not array
		//stringify and check even if array is passed
		let language = result?.profile?.language?.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			await locale.set(language);
		}
	}
	if (result?.profile?.pictures) {
		result.profile.pictures = addSourcePropToPictureObj(result.profile.pictures);
	}
	if (result?.upgrade) {
		showNotification = false;
		showUpgradePreferredModal.set(result?.upgrade);

		//Start of Wiz Upgrade Funnel
		//wizard upgrade funnel state is not already sent + is logged in + is in wizard
		const isInWizard = !result?.actions?.doneWizardAt; //this flag is sent only when user completes wizard
		if (!sessionStorage.wiz_upgrade_funnel && result.isPersonalLoggedIn && isInWizard) {
			const contact_slug = result?.upgrade?.preferred?.slug;
			const email_domain = result?.upgrade?.preferred?.user_name?.split('@')?.[1];
			const upgrade_slug = result?.upgrade?.upgrade?.slug;
			sessionStorage.setItem('wiz_upgrade_funnel', 'wiz_upgrade_prompt');
			logPlausibleEvent({
				n: 'Wiz Upgrade Prompt',
				p: { contact_slug, email_domain, upgrade_slug },
				u: '/'
			});
		}
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	checkVersion(result);

	const notificationObj = result.notification;
	if (showNotification && notificationObj) {
		if (notificationObj?.type === 'link') {
			logPlausibleEvent({ u: `/link/${notificationObj?.slug}`, n: 'action' });
		} else if (notificationObj?.type === 'authority') {
			const id =
				notificationObj.preferred || notificationObj.recovery || notificationObj.no_recovery;
			const slug = result.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (notificationObj?.type !== 'merge') {
			if (notificationObj?.attribute && notificationObj?.slug) {
				const claim =
					notificationObj.attribute.charAt(0).toUpperCase() +
					notificationObj.attribute.substring(1);
				notification.show(
					readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(notificationObj?.slug)
						}
					}),
					'success'
				);
			} else if (notificationObj?.slug) {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(notificationObj?.slug)
						}
					}),
					'success'
				);
			}
		}

		if (notificationObj.error === 'MANAGED_ACCOUNT_LOGGED_IN' && notificationObj.domain) {
			notification.show(
				readTranslatedKey(
					'{domain} is managed by an organization and cannot be your preferred personal provider',
					{
						values: {
							domain: notificationObj.domain
						}
					}
				),
				'error'
			);
		}
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return result;
};

export const getInvite = async (query, { showNotification = true } = {}) => {
	let url = '/invite';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	const paramsStr = params.toString();
	if (paramsStr) {
		url += `?${paramsStr}`;
	}
	const result = await get(url);
	if (result?.upgrade) {
		showNotification = false;
		showUpgradePreferredModal.set(result?.upgrade);
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	checkVersion(result);
	if (showNotification && result.notification) {
		if (result.notification?.type === 'link') {
			logPlausibleEvent({ u: `/link/${result.notification?.slug}`, n: 'action' });
		} else if (result.notification?.type === 'authority') {
			const id = result.notification.preferred || result.notification.recovery;
			const slug = result.profile?.accounts?.find((i) => i.id === id).slug;
			logPlausibleEvent({ u: `/verify/${slug}`, n: 'action' });
		}

		if (result.notification?.type !== 'merge') {
			if (result.notification?.attribute && result.notification?.slug) {
				const claim =
					result.notification.attribute.charAt(0).toUpperCase() +
					result.notification.attribute.substring(1);
				notification.show(
					readTranslatedKey(`${claim} from {provider} has been added`, {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					'success'
				);
			} else if (result.notification?.slug) {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					'success'
				);
			}
		}
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return result;
};

export const postDeviceNoPrompt = async (device_id) => {
	if (!device_id) {
		console.info('device_id missing');
		return;
	}
	return await post('/device/no_prompt/' + device_id);
};

export const deleteDeviceNoPrompt = async (device_id) => {
	if (!device_id) {
		console.error('device_id not passed in');
		return;
	}
	return await del('/device/no_prompt/' + device_id);
};

const listenForInAppMessages = () => {
	const evtSource = new EventSource('/api/v1/login/in_app');
	evtSource.addEventListener('keep-alive', (event) => {
		if (!IS_PROD) {
			console.log('keep-alive: ' + event.data);
		}
	});
	evtSource.addEventListener('authorization_response', (event) => {
		try {
			const json = JSON.parse(event.data);
			if (!json) throw json;
			data.set({}); //reset data
			window.location.href = window.location.pathname + '#' + new URLSearchParams(json);
		} catch (err) {
			console.error(err);
		} finally {
			evtSource.close();
		}
	});
};

const delayedListenForInAppMessages = () => setTimeout(listenForInAppMessages, 1000);

export const postLoginProvider = async ({
	slug = '',
	body = {},
	server = '',
	access = false
} = {}) => {
	let url = '';
	if (access) {
		url = '/login/access/redirect/';
	} else {
		url = '/login/redirect/';
	}
	url += slug;
	const queryParams = new URLSearchParams();
	if (window.isWalletApp) {
		queryParams.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		queryParams.set('redirect_path', '/authorize');
	} else if (window.isWalletMastodonApp) {
		queryParams.set('redirect_path', '/mastodon');

		//send to mastodon/twitter page if logging in with mastodon
		if (slug === 'mastodon') {
			sessionStorage.setItem('currentMastodonPage', '/twitter');
		}
	} else if (window.isWalletInviteApp) {
		queryParams.set('redirect_path', '/invite');
	}
	if (server) {
		queryParams.set('server', server);
	}
	if (access) {
		await logPlausibleEvent({ u: `/start/login/access/${slug}`, n: 'action' });
	} else {
		await logPlausibleEvent({ u: `/start/login/${slug}`, n: 'action' });
	}
	const paramsStr = queryParams.toString();
	if (paramsStr) {
		url += '?' + paramsStr;
	}
	const res = await post(url, body);
	if (!res?.redirect) throw new Error('Could not find redirect_uri');
	delayedListenForInAppMessages();
	return res;
};

export const postLinkProvider = async ({
	slug = '',
	body = {},
	attribute = '',
	server = '',
	access = false
} = {}) => {
	let url = '';
	if (access) {
		url = '/link/access/redirect/';
	} else {
		url = '/link/redirect/';
	}
	url += slug;
	if (attribute) url += '/' + attribute;
	const queryParams = new URLSearchParams();
	if (window.isWalletApp) {
		queryParams.set('redirect_path', '/');
	} else if (window.isWalletAuthorizeApp) {
		queryParams.set('redirect_path', '/authorize');
	} else if (window.isWalletMastodonApp) {
		queryParams.set('redirect_path', '/mastodon');

		//send to mastodon/twitter page if linking mastodon
		//dont overwrite any existing sessionstorage page,
		// eg: linking avatar with mastodon should not redirect to metadata page
		//if mastodon acc linked from eg: header page (attribute), we dont want to send back to avatar
		if (
			slug === 'mastodon' &&
			!attribute &&
			(!sessionStorage.page || sessionStorage.page === '/')
		) {
			sessionStorage.setItem('currentMastodonPage', '/twitter');
		}
	} else if (window.isWalletInviteApp) {
		queryParams.set('redirect_path', '/invite');
	}
	if (server) {
		queryParams.set('server', server);
	}
	if (access) {
		await logPlausibleEvent({ u: `/start/link/access/${slug}`, n: 'action' });
	} else {
		await logPlausibleEvent({ u: `/start/link/${slug}`, n: 'action' });
	}
	const paramsStr = queryParams.toString();
	if (paramsStr) {
		url += '?' + paramsStr;
	}
	const res = await post(url, body);
	if (!res?.redirect) throw new Error('Could not find redirect_uri');
	delayedListenForInAppMessages();
	return res;
};

export const postLoginEmail = async (email, resend) => {
	const res = await post('/login/contact/email', {
		email
	});
	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/login/email', n: 'action' });
	}
	return res;
};

export const postLoginEmailCode = async (body) => {
	const res = await post(
		`/login/contact/email/code?prefers-color-scheme=${getDeviceTheme()}&language=${
			window.navigator.language
		}`,
		body
	);
	notification.clear();
	logPlausibleEvent({ u: '/login/email', n: 'action' });
	return res;
};

export const postLoginPhone = async (phone, resend) => {
	const res = await post('/login/contact/phone', {
		phone
	});
	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/login/phone', n: 'action' });
	}
	return res;
};

export const postLoginPhoneCode = async (body) => {
	const res = await post(
		`/login/contact/phone/code?prefers-color-scheme=${getDeviceTheme()}&language=${
			window.navigator.language
		}`,
		body
	);
	notification.clear();
	logPlausibleEvent({ u: '/login/phone', n: 'action' });
	return res;
};

export const postVerifyEmail = async (email, resend) => {
	const res = await post('/verify/contact/email', {
		email
	});

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/verify/email', n: 'action' });
	}
	return res;
};

export const postVerifyEmailCode = async (code) => {
	const res = await post('/verify/contact/email/code', {
		code
	});
	logPlausibleEvent({ u: '/verify/email', n: 'action' });
	return res;
};

export const postVerifyPhone = async (phone, resend) => {
	const res = await post('/verify/contact/phone', {
		phone
	});

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/verify/phone', n: 'action' });
	}
	return res;
};

export const postVerifyPhoneCode = async (code) => {
	const res = await post('/verify/contact/phone/code', {
		code
	});
	logPlausibleEvent({ u: '/verify/phone', n: 'action' });
	return res;
};

export const postLoginEth = async (address) => {
	return await post('/login/ethereum/challenge', {
		address,
		uri: window.location.origin + window.location.pathname
	});
};

export const postLoginEthChallenge = async (body) => {
	return await post(
		`/login/ethereum?prefers-color-scheme=${getDeviceTheme()}&language=${
			window.navigator.language
		}`,
		body,
		null
	);
};

export const postLinkEth = async (address) => {
	return await post('/link/ethereum/challenge', {
		address,
		uri: window.location.origin + window.location.pathname
	});
};

export const postLinkEthChallenge = async (body) => {
	return await post('/link/ethereum', body);
};

export const postLinkEmail = async (email, resend) => {
	const res = await post('/link/contact/email', {
		email
	});

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: email }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/link/email', n: 'action' });
	}
	return res;
};

export const postLinkEmailCode = async (code, showNotification = true) => {
	const res = await post('/link/contact/email/code', {
		code
	});
	if (!res.merge && showNotification) {
		notification.show(readTranslatedKey('Email has been added'), 'success');
	}
	logPlausibleEvent({ u: '/link/email', n: 'action' });
	return res;
};

export const postLinkPhone = async (phone, resend) => {
	const res = await post('/link/contact/phone', {
		phone
	});

	if (resend) {
		notification.show(
			readTranslatedKey('Resent verification code to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
	} else {
		notification.show(
			readTranslatedKey('Verification code sent to {contact}', {
				values: { contact: phone }
			}),
			'success'
		);
		logPlausibleEvent({ u: '/start/link/phone', n: 'action' });
	}
	return res;
};

export const postLinkPhoneCode = async (code, showNotification = true) => {
	const res = await post('/link/contact/phone/code', {
		code
	});
	if (!res.merge && showNotification) {
		notification.show(readTranslatedKey('Phone has been added'), 'success');
	}
	logPlausibleEvent({ u: '/link/phone', n: 'action' });
	return res;
};

export const postNoPrompt = async () => {
	return await post('/login/no_prompt');
};

export const deleteNoPrompt = async () => {
	return await del('/login/no_prompt');
};

export const postWizard = async () => {
	return await post('/profile/wizard');
};

export const deleteWizard = async () => {
	return await del('/profile/wizard');
};

export const getConsent = async (query, { showNotification = true } = {}) => {
	let url = '/consent';
	let params = new URLSearchParams(query);
	if (query) {
		params.append('prefers-color-scheme', getDeviceTheme());
		params.append('language', window.navigator.language);
	}
	const paramsStr = params.toString();
	if (paramsStr) {
		url += `?${paramsStr}`;
	}
	const result = await get(url);
	if (result?.language) {
		//svelte-i18n crashes if initialLocale has commas
		//expected type for result?.language is string and not array
		//stringify and check even if array is passed
		let language = result?.language?.toString()?.split(',')?.[0];
		if (language) {
			localStorage.setItem('lang', language);
			await locale.set(language);
		}
	}
	if (result?.release?.pictures) {
		result.release.pictures = addSourcePropToPictureObj(result.release.pictures);
	}
	if (result?.upgrade) {
		showNotification = false;

		showUpgradePreferredModal.set(result?.upgrade);
	}
	if (result?.merge) {
		showNotification = false;
		mergeAccounts.set(result?.merge);
	}
	if (result?.remoteDevice) {
		isRemoteAuthClient.set(true);
	}
	checkVersion(result);

	if (showNotification && result.notification?.type !== 'merge') {
		if (
			result.notification &&
			!['verified-name', 'existing-name', 'existing-username'].includes(
				result.notification?.attribute
			)
		) {
			if (result.notification?.attribute) {
				notification.show(
					`${
						result.notification.attribute.charAt(0).toUpperCase() +
						result.notification.attribute.substring(1)
					} from ${getDisplay(result.notification?.slug)} has been added`,
					'success'
				);
			} else if (result.notification.error === 'PERSONAL_REQUESTED') {
				notification.show(
					readTranslatedKey(
						`A personal account was requested. {provider} ({email}) is a managed account`,
						{
							values: {
								provider: getDisplay(result.notification?.slug),
								email: result.notification.lastLogin
							}
						}
					),
					'error'
				);
			} else if (result.notification.error === 'MANAGED_REQUESTED') {
				notification.show(
					readTranslatedKey(
						`A managed account was requested. {provider} ({email}) is a personal account`,
						{
							values: {
								provider: getDisplay(result.notification?.slug),
								email: result.notification.lastLogin
							}
						}
					),
					'error'
				);
			} else {
				notification.show(
					readTranslatedKey('{provider} has been added', {
						values: {
							provider: getDisplay(result.notification?.slug)
						}
					}),
					'success'
				);
			}
		}
	}

	if (result.app) {
		sessionStorage.setItem('app', JSON.stringify(result.app));
	}

	if (sessionStorage.showWalletMergedNotification) {
		//show notification that was set in sessionstorage before page reload - page was reloaded to get latest state
		notification.show(readTranslatedKey('Wallets merged successfully'), 'success');
		sessionStorage.removeItem('showWalletMergedNotification');
	}

	return result;
};

export const postConsent = async (body) => {
	return await post('/consent', body);
};

export const deleteConsent = async () => {
	try {
		showSpinner.set(true);
		const res = await del('/consent');
		handleConsentResponse(res);
	} catch (err) {
		showSpinner.set(false);
		console.error(err);
	}
};

const namesMap = {
	name: 'Full name',
	nickname: 'Preferred name',
	given_name: 'First name',
	family_name: 'Last name'
};

export const putName = async (type, name, showNotification = true) => {
	const res = await put(`/profile/${type}`, { [type]: name });

	const claim = namesMap[type];
	if (claim && showNotification) {
		notification.show(readTranslatedKey(`${claim} added`), 'success');
	}

	return res;
};

export const deleteName = async (type, index) => {
	const res = await del(`/profile/${type}?ordinal=${index}`);

	const claim = namesMap[type] || 'Entry';
	if (claim) {
		notification.show(readTranslatedKey(`${claim} removed`), 'success');
	}

	return res;
};

export const putPreferred = async (action, { redirectPathParam = false, server = null } = {}) => {
	let url = '/profile/authority';
	if (redirectPathParam) {
		const params = new URLSearchParams();
		if (window.isWalletApp) {
			params.set('redirect_path', '/');
		} else if (window.isWalletAuthorizeApp) {
			params.set('redirect_path', '/authorize');
		} else if (window.isWalletMastodonApp) {
			params.set('redirect_path', '/mastodon');
		} else if (window.isWalletInviteApp) {
			params.set('redirect_path', '/invite');
		}
		if (server) {
			params.set('server', server);
		}
		const paramsToString = params.toString();
		if (paramsToString) {
			url += `?${paramsToString}`;
		}
	}
	return await put(url, action);
};

export const deleteProfile = async () => {
	clearLocalAndSessionStorage();
	return await del('/profile');
};

export const postPicture = async (formData) => {
	const res = await post('/profile/picture', formData, true);

	notification.show(readTranslatedKey('Profile picture added'), 'success');

	return res;
};

export const postLogo = async (id, formData) => {
	const logo = await post('/managed/' + id + '/logo', formData, true);
	const img = new Image();
	img.src = logo.url;
	const promise = new Promise((resolve) => {
		img.onload = () => {
			logo.width = img.naturalWidth;
			logo.height = img.naturalWidth;
			resolve(logo);
		};
		img.onerror = () => {
			resolve(logo);
		};
	});
	return promise;
};

export const postBanner = async (formData) => {
	const res = await post('/profile/banner', formData, true);

	notification.show('Banner added', 'success');

	return res;
};

export const deletePicture = async (index) => {
	const res = await del(`/profile/picture?ordinal=${index}`, null);

	notification.show(readTranslatedKey('Profile picture removed'), 'success');

	return res;
};

export const deleteDevice = async (id) => {
	const res = await del('/profile/device/' + id);

	notification.show(readTranslatedKey('Device removed'), 'success');

	return res;
};

export const deleteApplication = async (id) => {
	const res = await del('/profile/application/' + id);

	notification.show(readTranslatedKey('Application removed'), 'success');

	return res;
};

export const deleteManagedApplication = async ({ id, sub } = {}) => {
	const res = await del('/profile/application/' + id + '/managed?sub=' + encodeURIComponent(sub));

	notification.show(readTranslatedKey('Application removed'), 'success');

	return res;
};

export const deleteProvider = async (id) => {
	const res = await del('/profile/account', { id });

	notification.show(readTranslatedKey('Provider removed'), 'success');

	return res;
};

export const deleteUnverifiedProvider = async (index, type) => {
	const res = await del(`/profile/unverified_${type}?ordinal=${index}`);

	notification.show(
		type === 'email'
			? readTranslatedKey('Unverified email removed')
			: type === 'phone'
			? readTranslatedKey('Unverified phone removed')
			: readTranslatedKey('Unverified account removed'),
		'success'
	);

	return res;
};

export const logout = async ({ clearSession = true } = {}) => {
	countdown.clear();
	if (clearSession) {
		clearLocalAndSessionStorage();
	}
	const res = await del('/login');
	await logPlausibleEvent({ u: '/logout', n: 'action' });
	return res;
};

export const keepAlive = async () => {
	return await get('/login/keep_alive');
};

export const changeLanguage = async (langShortCode) => {
	return await put('/profile/language/' + langShortCode);
};

export const generateBanner = async (prompt) => {
	return await post('/profile/generate/banner', { prompt });
};

export const deleteAccessToken = async (ordinal = 0) => {
	return await del(`/access?ordinal=${ordinal}`);
};

export const postRelMeMastodon = async (body) => {
	return await post('/profile/rel-me/mastodon', body);
};

export const deleteCookies = async () => {
	return await del('/cookies');
};

export const getMastodonVerifyCredentials = async (server, token) => {
	keepAlive(); //This is not Hellō API - so renew session manually
	try {
		const res = await fetch('https://' + server + '/api/v1/accounts/verify_credentials', {
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
		return await res.json();
	} catch (err) {
		console.error(err);
		notification.show(`Something went wrong. It's not your fault.`, 'error');
		throw err;
	}
};

export const getMastodonFollowings = async (server, id) => {
	keepAlive(); //This is not Hellō API - so renew session manually
	let nextPageEndpoint = true;
	let followings = [];
	while (nextPageEndpoint) {
		try {
			let res;
			if (typeof nextPageEndpoint === 'string' && nextPageEndpoint.startsWith('http')) {
				res = await fetch(nextPageEndpoint);
			} else {
				res = await fetch(`https://${server}/api/v1/accounts/${id}/following?limit=80`);
			}
			const json = await res.json();
			if (json?.length) {
				followings = [...followings, ...json];
			}
			const linkResHeader = res.headers.get('Link');
			if (linkResHeader?.includes(`rel="next"`)) {
				const nextPageLinkResHeader = linkResHeader.split(';')?.[0];
				nextPageEndpoint = nextPageLinkResHeader?.substring(1, nextPageLinkResHeader.length - 1);
			} else {
				nextPageEndpoint = false;
			}
		} catch (err) {
			console.error(err);
			notification.show("Something went wrong. It's not your fault.", 'error');
			break;
		}
	}
	return followings;
};

export const postFollowMastodonAccount = async (server, id, token) => {
	try {
		const res = await fetch(`https://${server}/api/v1/accounts/${id}/follow`, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const postUnfollowMastodonAccount = async (server, id, token) => {
	try {
		const res = await fetch(`https://${server}/api/v1/accounts/${id}/unfollow`, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + token
			}
		});
		if (!res.ok) throw res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const getMastodonDiscovery = async (server) => {
	const discoEndpoint = '/api/v1/nodeinfo/2.0?server=' + server;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (!discoRes.ok) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const getManagedDicsovery = async (email) => {
	const discoEndpoint = '/api/v1/managed/provider?email=' + email;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (!discoRes.ok) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const getEmailProviderDiscovery = async (domain) => {
	const discoEndpoint = '/api/v1/email/provider/' + domain;
	//Not using helper GET method
	//this call can fail and helper GET puts up an error notification which we dont want
	const discoRes = await fetch(discoEndpoint);
	if (discoRes.status !== 200) throw discoRes;
	const discoJson = await discoRes.json();
	return discoJson;
};

export const postInvite = async (invitee = { email: null, local: false }) => {
	return await post('/invite', invitee);
};

export const putInvite = async (id) => {
	return await put('/invite/' + id);
};

export const deleteInvite = async (id) => {
	return await del('/invite/' + id);
};

export const postInviter = async (inviter = { name: null, email: null }) => {
	return await post('/inviter', inviter);
};

export const postUserCode = async (code) => {
	return await post('/user/code', { user_code: code });
};

export const deleteUserCode = async (code) => {
	return await del('/user/code?user_code=' + code);
};

export const getInvitation = async (id) => {
	return await get('/invitation/' + id);
};

export const acceptInvitation = async (id) => {
	return await put('/invitation/' + id);
};

export const deleteInvitation = async (id) => {
	return await del('/invitation/' + id);
};

export const postReportAbuse = async (id) => {
	return await post('/invitation/' + id + '/report');
};

export const getManagedLogos = async (id) => {
	const logos = await get('/managed/' + id + '/logos');
	let promises = [];
	for (const logo of logos) {
		const img = new Image();
		img.src = logo.url;
		const promise = new Promise((resolve) => {
			img.onload = () => {
				logo.width = img.naturalWidth;
				logo.height = img.naturalWidth;
				resolve(logo);
			};
			img.onerror = () => {
				console.warn('Failed to fetch logo at: ' + logo.url);
				resolve();
			};
		});
		promises.push(promise);
	}
	const res = await Promise.all(promises);
	return res.filter(Boolean); //filter out unresolved images
};

export const putManaged = async (id, body) => {
	return await put('/managed/' + id + '/who', body);
};

export const putManagedLogo = async (id, body) => {
	return await put('/managed/' + id + '/logo/select', body);
};

// this fn gets called onerror, onunhandledrejection, and directly
export const postClientError = async (err, state) => {
	console.error(err);
	// onerror if sync error, unhandledrejection if async / promise error
	// .error = onerror, .reason = unhandledrejection, err = postClientError is called directly with JS Error obj
	const error = err?.error || err?.reason || err;
	const stack = error?.stack;
	if (!stack) {
		//errors thrown from our web app are guaranteed to have stack
		console.error('Error from third-party script', err);
		return;
	}
	const message = error?.message;
	const profileConsentRes = getStore(data); // response from GET /profile | GET /consent
	const body = {
		message,
		stack,
		state: {
			...state,
			href: window.location.href,
			profileConsentRes,
			sessionStorage: window.sessionStorage,
			localStorage: window.localStorage,
			navigator: {
				...parser(window.navigator),
				languages: window.navigator.languages
			}
		}
	};

	// dont use helper api fns here
	// we dont want it to be recursive (enter infinite loop) if something fails
	try {
		await fetch('/api/v1/client/error', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		});
	} catch (err) {
		console.error('failed to post error to slack', err);
	}
};

export const getM2Org = async (query) => {
	return await get('/m2/organization?' + query);
};

export const getM2OrgUsers = async (id) => {
	return await get('/m2/organization/' + id + '/users');
};

export const putM2OrgDomain = async (id, domain) => {
	return await put('/m2/organization/' + id + '/domain', { domain });
};

export const putM2OrgLogo = async (id, body) => {
	return await put('/m2/organization/' + id + '/logo', body);
};

export const putM2Managed = async (id, body) => {
	return await put('/m2/organization/' + id + '/who', body);
};

//tbd: this is duplicate of getting managed logos (not m2)
export const getM2ManagedLogos = async (id) => {
	const logos = await get('/m2/organization/' + id + '/logos');
	let promises = [];
	for (const logo of logos) {
		const img = new Image();
		img.src = logo.url;
		const promise = new Promise((resolve) => {
			img.onload = () => {
				logo.width = img.naturalWidth;
				logo.height = img.naturalWidth;
				resolve(logo);
			};
			img.onerror = () => {
				console.warn('Failed to fetch logo at: ' + logo.url);
				resolve();
			};
		});
		promises.push(promise);
	}
	const res = await Promise.all(promises);
	return res.filter(Boolean); //filter out unresolved images
};

//tbd: this is duplicate of getting managed logos (not m2)
export const postM2Logo = async (id, formData) => {
	const logo = await post('/m2/organization/' + id + '/logo', formData, true);
	const img = new Image();
	img.src = logo.url;
	const promise = new Promise((resolve) => {
		img.onload = () => {
			logo.width = img.naturalWidth;
			logo.height = img.naturalWidth;
			resolve(logo);
		};
		img.onerror = () => {
			resolve(logo);
		};
	});
	return promise;
};

//passkeys start
export const getCreateChallenge = async () => {
	return await get('/passkey/create');
};

export const getLoginChallenge = async () => {
	return await get('/passkey/login');
};

export const postCreateChallenge = async (credential) => {
	return await post('/passkey/create', credential);
};

export const postLoginChallenge = async (credential) => {
	return await post('/passkey/login', credential);
};
export const postNoPromptPasskey = async () => {
	return await post('/passkey/no_prompt');
};
//passkeys end
