import { notification, data } from '$src/stores.js';
import { readTranslatedKey } from './i18n.js';
import { replace } from 'svelte-spa-router';

const notifications = {
	DUPLICATE_VALUE: 'Entry already exists',
	INVALID_CODE: 'Incorrect verification code',
	NOT_USER_SUBJECT: 'Account already linked with another wallet',
	NO_SESSION: 'Session expired. Please log in again.',
	CANNOT_HAVE_MORE_THAN_ONE_RECOVERY_FROM_SAME_ORG:
		'Cannot have more than one recovery from the same organization',
	DEFAULT: 'Something went wrong. Please try again later.',
	EMAIL_ALREADY_VERIFIED: 'Email has already been verified',
	PHONE_ALREADY_VERIFIED: 'Phone has already been verified',
	USER_NOT_LOGGED_IN: 'Session expired. Please log in again.'
};

const handleErrorJson = (error) => {
	// tbd assert json.message exists

	// we handle notification in page
	if (['NO_SESSION_REQUEST', 'NO_SESSION_RESPONSE'].includes(error.message)) {
		return;
	}

	// authz errors - we handle this in page
	if (
		['UNCONFIGURED_REDIRECT_URI', 'UNSUPPORTED_REDIRECT_URI', 'ACCESS_DENIED'].includes(
			error.message
		)
	) {
		return;
	}

	// invite errors - we handle this in page
	if (
		['NO_MX_RECORD', 'INVITER_HAS_INVITED_INVITEE', 'USER_ALREADY_USING_APP'].includes(
			error.message
		)
	) {
		return;
	}

	// invitation errors - we handle this in page
	if (['INVITATION_NOT_FOUND', 'INVITATION_EXPIRED'].includes(error.message)) {
		return;
	}

	if (['NO_SESSION', 'USER_NOT_LOGGED_IN'].includes(error.message)) {
		//clear stale data
		//data will be refetched again at login page
		data.set({});

		return replace('/login');
	}

	notification.show(
		readTranslatedKey(notifications[error.message] || notifications.DEFAULT),
		'error'
	);
};

export { handleErrorJson };
