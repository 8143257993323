export const SESSION_EXPIRE_MS = 270 * 1000; // 4.5min - Counts down to SESSION_EXPIRE_SHOW_MODAL_MS
export const SESSION_EXPIRE_SHOW_MODAL_MS = 30 * 1000; // 30sec
export const SESSION_MODAL_WARNING_MS = 5000; // 5 sec
export const CLIENT_REQUEST_TIMEOUT_MS = 3 * 60 * 1000; // 3min
export const WALLETCONNECT_CONFIG = {
	projectId: '808b0a90aff8b15ae599eb5328140cb0', //WalletConnect Project ID for Hellō
	modalOptions: {
		themeMode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
	},
	metadata: {
		name: 'Hellō',
		description: 'Hellō',
		url: 'wallet.hello.coop',
		icons: ['https://cdn.hello.coop/images/hello.png']
	}
};
export const DEFAULT_MANAGED_LOGO = 'https://cdn.hello.coop/images/default-org.png';
export const DEFAULT_PERSONAL_LOGO = 'https://cdn.hello.coop/images/default-personal.svg';
export const RECOMMENDED_MANAGED_LOGO_SIZE = 72;
export const API_BASE = '/api/v1';
export const IS_PROD = window.location.hostname === 'wallet.hello.coop';
