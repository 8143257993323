import { API_BASE, CLIENT_REQUEST_TIMEOUT_MS } from '$src/constants.js';
import { countdown, notification } from '$src/stores.js';
import { handleErrorJson } from './errors';
import { readTranslatedKey } from './i18n';
import { postClientError } from './api.js';

const timeoutPromise = (promise) => {
	return new Promise((resolve, reject) => {
		const timeoutId = setTimeout(() => {
			reject(new Error(408));
		}, CLIENT_REQUEST_TIMEOUT_MS);
		promise.then(
			(res) => {
				clearTimeout(timeoutId);
				resolve(res);
			},
			(err) => {
				clearTimeout(timeoutId);
				reject(err);
			}
		);
	});
};

// deprecate - only being used in mastodon app
const fetchWithTimeout = async (resource, options = {}) => {
	const { timeout = 8000 } = options;

	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	try {
		const response = await fetch(resource, {
			...options,
			signal: controller.signal
		});
		clearTimeout(id);
		return response;
	} catch (err) {
		if (err?.name === 'AbortError') {
			console.info(`Request for ${resource} timed out (${timeout}ms)`);
		}
		throw err;
	}
};

// deprecate - only being used in mastodon app
const fetchWithRetry = async (resource, options = {}) => {
	const { totalRetries = 1, retryOnStatusCode = 401 } = options;
	let retries = 0;
	while (retries <= totalRetries) {
		const res = await fetch(resource, options);
		if (!res.ok) {
			if (retries === totalRetries) return res;
			if (res.status === retryOnStatusCode) {
				console.info(`Retrying request to ${resource}`);
				retries++;
				continue;
			}
		}
		return res;
	}
};

const send = async ({ method, path, data, picture }) => {
	const opts = { method, headers: {} };
	if (picture) {
		opts.body = data;
	} else if (data) {
		opts.headers['Content-Type'] = 'application/json';
		opts.body = JSON.stringify(data);
	}

	const url = API_BASE + path;
	let res;
	try {
		res = await timeoutPromise(fetch(url, opts));
	} catch (err) {
		// request timed out
		notification.show(readTranslatedKey('Request timed out. Please try again.'), 'error');
		throw err;
	}

	try {
		if (!res.ok) {
			// client or server errors
			const msg = `Error ${res.status} on ${method} ${url}`;
			const error = new Error(msg);
			error.status = res.status;
			try {
				const json = await res.json();
				error.json = json;
			} catch (err) {
				error.json = 'failed to parse json response';
			}
			throw error;
		}
		const json = await res.json();
		if (json.error) {
			// user errors
			handleErrorJson(json.error);
			const error = new Error(json?.error?.message);
			error.response = json?.error;
			throw error;
		}
		const isLoggedIn =
			('isPersonalLoggedIn' in json && json.isPersonalLoggedIn) ||
			('isManagedLoggedIn' in json && json.isManagedLoggedIn);
		if (isLoggedIn) {
			countdown.start();
		} else {
			countdown.clear();
		}
		return json;
	} catch (err) {
		if (err instanceof TypeError) {
			notification.show(readTranslatedKey('Network error. Please try again.'), 'error');
		} else if (err.status) {
			// server did not process
			if (err.status >= 500) {
				notification.show(
					readTranslatedKey('Something went wrong. Please try again later.'),
					'error'
				);
			} else {
				if (!err.json?.notification)
					notification.show(
						readTranslatedKey('Something went wrong. Please try again later.'),
						'error'
					);
				postClientError(err, { apiRes: err?.json, apiReq: opts });
			}
		} else if (err.response) {
			// server sent an error response
			throw err.response;
		} else {
			// unexpected error
			notification.show(
				readTranslatedKey('Something went wrong. Please try again later.'),
				'error'
			);
			postClientError(err, { apiRes: err?.json, apiReq: opts });
		}

		throw err;
	}
};

const get = (path) => {
	return send({ method: 'GET', path });
};

const del = (path, data) => {
	return send({ method: 'DELETE', path, data });
};

const post = (path, data, picture) => {
	return send({ method: 'POST', path, data, picture });
};

const put = (path, data) => {
	return send({ method: 'PUT', path, data });
};

export { fetchWithTimeout, fetchWithRetry, get, del, post, put };
