import parser from 'ua-parser-js';
import logins from '$svr/providers/logins.json';
import { version } from '../../../../package.json';
import { locale } from 'svelte-i18n';

const checkVersion = (result) => {
	const versionServer = result && result.version;
	const iso = new Date().toISOString();
	if (versionServer) {
		const color = version === versionServer ? 'color: green;' : 'color: red;';
		console.log(`%c Running Client v${version} | Server v${versionServer} (${iso})`, color);
	}
};

const getDeviceTheme = () => {
	if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
		return 'dark';
	} else {
		return 'light';
	}
};

const getDisplay = (slug) => {
	if (!slug) return '';
	const display = logins.find((i) => i.slug === slug)?.display;
	if (display) return display;
	else return slug.charAt(0).toUpperCase() + slug.slice(1);
};

const setAttributes = (node) => {
	node.setAttribute('nosociallinks', true);
	locale.subscribe((lang) => {
		if (lang) {
			node.setAttribute('lang', lang.split('-')[0]);
		}
	});
};

const isValidDomain = function (domain) {
	// TODO: accept http://example.com, https://www.example.com but only send example.com to server
	return /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,63}$/.test(domain);
};

const abbreviateNumber = (number) => {
	//https://stackoverflow.com/a/40724354
	//TODO: Use Intl.NumberFormat API when better supported
	const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

	// what tier? (determines SI symbol)
	const tier = (Math.log10(Math.abs(number)) / 3) | 0;

	// if zero, we don't need a suffix
	if (tier == 0) return number;

	// get suffix and determine scale
	const suffix = SI_SYMBOL[tier];
	const scale = Math.pow(10, tier * 3);

	// scale the number
	const scaled = number / scale;

	// format number and add suffix
	return scaled.toFixed(1) + suffix;
};

const makeLabel = (obj) => {
	const entries = Object.keys(obj).length;
	if (!entries) return '';
	const labels = [];
	const firstLabel = obj[Object.keys(obj)[0]]?.label;
	if (firstLabel) {
		labels.push(firstLabel);
	}
	if (entries > 1) {
		const secondLabel = obj[Object.keys(obj)[1]]?.label;
		if (secondLabel) {
			labels.push(secondLabel);
		}
	}
	if (labels.length) {
		return `(${labels.join(' & ')})`;
	}
	return '';
};

const getRecommendedProviders = (providerHintsShown, providerHintsHidden) => {
	let recommendedProviders = ['google', 'email']; //defaults
	let _recommendedProviders = Array.from(recommendedProviders);
	if (
		parser(window.userAgent).os?.name?.includes('Mac') ||
		parser(window.userAgent).os?.name?.includes('iOS')
	) {
		recommendedProviders = _recommendedProviders = ['apple', ...recommendedProviders];
	} else if (parser(window.userAgent).os?.name?.includes('Windows')) {
		recommendedProviders = _recommendedProviders = ['microsoft', ...recommendedProviders];
	}
	//query param slugs
	if (providerHintsShown?.length) {
		recommendedProviders = [...providerHintsShown, ...recommendedProviders];
	}
	//query param slugs suffixed with --
	if (providerHintsHidden?.length) {
		recommendedProviders = recommendedProviders.filter((i) => !providerHintsHidden.includes(i)); //remove slugs suffixed with --
	}
	//defense for hiding all above fold provider via provider_hint query params
	if (!recommendedProviders?.length) {
		recommendedProviders = Array.from(_recommendedProviders);
		console.warn(
			'Reverting to showing default providers. You cannot demote all default providers.'
		);
	}
	recommendedProviders = [...new Set(recommendedProviders)]; //remove duplicates
	return recommendedProviders;
};

const dedupe = (arr, key) => {
	//not an object
	if (!key) {
		const deduped = [...new Set(arr)];
		if (deduped.length !== arr.length) {
			console.warn(`Found duplicates in ${JSON.stringify(arr, null, 2)}`);
		}
		return deduped;
	}

	const deduped = [];
	let hasDuplicates = false;
	for (const item of arr) {
		const keys = deduped.map((i) => i[key]);
		if (!keys.includes(item[key])) {
			deduped.push(item);
		} else {
			hasDuplicates = true;
		}
	}
	if (hasDuplicates) {
		console.warn(`(Using key: "${key}") Found duplicates in ${JSON.stringify(arr, null, 2)}`);
	}
	return deduped;
};

const addSourcePropToPictureObj = (picturesArr) => {
	picturesArr.forEach((pictureObj) => {
		if (!pictureObj) return; //defense for null or undefined
		if (!Object.prototype.hasOwnProperty.call(pictureObj, 'source')) {
			if (pictureObj.default) {
				pictureObj.source = 'default';
			} else if (pictureObj.uploaded) {
				pictureObj.source = 'upload';
			} else {
				pictureObj.source = pictureObj.display;
			}
		}
	});
	return picturesArr;
};

export {
	checkVersion,
	getDeviceTheme,
	getDisplay,
	setAttributes,
	isValidDomain,
	abbreviateNumber,
	makeLabel,
	getRecommendedProviders,
	dedupe,
	addSourcePropToPictureObj
};
