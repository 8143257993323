import { IS_PROD } from '$src/constants.js';

export const logPlausibleEvent = async (body) => {
	if (localStorage.getItem('plausible_ignore') == 'true') {
		console.info('Ignoring Event: localStorage flag');
		return;
	}
	// https://plausible.io/docs/events-api
	const _body = {
		w: window.innerWidth, //window width
		r: document.referrer || null, //referer
		d: window.location.hostname, //domain
		...body, //u: url
		n: body.n || 'pageview' //type of event ['pageview', '<name of event>']
	};
	if (window.location.pathname === '/') {
		_body.u = window.location.origin + body.u;
	} else {
		_body.u = window.location.origin + window.location.pathname + (body.u || '');
	}
	try {
		await fetch('/api/event', {
			method: 'POST',
			body: JSON.stringify(_body),
			headers: {
				'Content-Type': 'application/json'
			}
		});
		if (!IS_PROD) {
			console.info(`Event sent: ${_body.u} (${_body.n})`);
		}
	} catch (err) {
		console.error(err);
	}
};
